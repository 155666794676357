import React, { Fragment, useState } from "react"
import { Link } from "gatsby"
import styles from "./menu.module.css"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const Menu = (props) => {
    const [open, setOpened] = useState(false)

    let menuBtn
    if (!props.isHorizontal) {
        menuBtn = (
            <a className={styles.menuBtn} href="#" onClick={(e) => setOpened(!open)}>
                <span></span>
                <span></span>
                <span></span>
            </a>
        )
    } else if (!open) {
        setOpened(true)
    }

    let menu
    if (open) {
        menu = (
            <ul
                className={`${props.light ? styles.light : ""} ${styles.menu} ${
                    props.isHorizontal ? styles.isHorizontal : styles.isVertical
                } `}
            >
                <li>
                    <AnchorLink to="/#inicio" title="Acerca de">
                        <span>Acerca de</span>
                    </AnchorLink>
                </li>
                <li>
                    <AnchorLink to="/#impacto" title="Impacto">
                        <span>Impacto</span>
                    </AnchorLink>
                </li>
                <li>
                    <AnchorLink to="/#plataformas" title="Plataformas">
                        <span>Plataformas</span>
                    </AnchorLink>
                </li>
                <li>
                    <AnchorLink to="/#clientes" title="Clientes y Alianzas">
                        <span>Clientes y Alianzas</span>
                    </AnchorLink>
                </li>
                <li>
                    <Link to="/prensa/">
                        <span>Prensa</span>
                    </Link>
                </li>
                <li>
                    <Link
                        to="/contacto/"
                        state={{
                            modal: true
                        }}
                    >
                        <span>Contacto</span>
                    </Link>
                </li>
            </ul>
        )
    }

    return (
        <Fragment>
            {menuBtn}
            {menu}
        </Fragment>
    )
}

export default Menu
