import React from "react"

export default function InnerContainer({ children, noPadding }) {
    return (
        <div
            style={{
                margin: "0 auto",
                maxWidth: 1100,
                padding: `${noPadding ? "0 1rem" : "3.2rem 1rem"}`
            }}
        >
            {children}
        </div>
    )
}
