import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Image from "gatsby-image"
import styles from "./header.module.css"
import InnerContainer from "./innerContainer"
import Menu from "./menu"

const Header = ({ title }) => {
    const data = useStaticQuery(graphql`
        query HeaderQuery {
            background: file(absolutePath: { regex: "/header.png/" }) {
                childImageSharp {
                    fluid(maxWidth: 1800, quality: 100) {
                        src
                    }
                }
            }
            logo: file(absolutePath: { regex: "/logo.png/" }) {
                childImageSharp {
                    fixed(width: 300, quality: 100) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    return (
        <div className={styles.header}>
            <div
                className={styles.upperBand}
                style={{
                    backgroundImage: `url(${data.background.childImageSharp.fluid.src})`
                }}
            >
                <p className={styles.bandText}>
                    <b>Innovación</b> en <b>Educación</b>
                </p>
            </div>
            <InnerContainer noPadding={true} noMargin={true}>
                <div className={styles.navigation}>
                    <Link to="/" className={styles.logo}>
                        <Image
                            fixed={data.logo.childImageSharp.fixed}
                            style={{
                                maxWidth: "100%"
                            }}
                            imgStyle={{
                                width: "100%",
                                height: "auto"
                            }}
                        />
                    </Link>
                    <Menu isHorizontal={true}></Menu>
                </div>
                <h1 className={styles.title}>{title}</h1>
            </InnerContainer>
        </div>
    )
}

export default Header
