import React from "react"
import styles from "./footer.module.css"
import { useStaticQuery, graphql, Link } from "gatsby"
import InnerContainer from "./innerContainer"
import Image from "gatsby-image"

const Footer = () => {
    const data = useStaticQuery(graphql`
        query FooterQuery {
            background: file(absolutePath: { regex: "/fondoFooter.png/" }) {
                childImageSharp {
                    fluid(maxWidth: 1800, quality: 100) {
                        src
                    }
                }
            }
            logoLight: file(absolutePath: { regex: "/logoLight.png/" }) {
                childImageSharp {
                    fixed(width: 300, quality: 100) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            crunchbase: file(absolutePath: { regex: "/crunchbase_400x400.png/" }) {
                childImageSharp {
                    fixed(width: 50, quality: 100) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            linkedin: file(absolutePath: { regex: "/linkedin_400x400.png/" }) {
                childImageSharp {
                    fixed(width: 50, quality: 100) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }

            site {
                siteMetadata {
                    social {
                        linkedin
                        crunchbase
                    }
                }
            }
        }
    `)
    const { social } = data.site.siteMetadata

    return (
        <footer
            className={styles.footer}
            style={{ backgroundImage: `url(${data.background.childImageSharp.fluid.src})` }}
        >
            <InnerContainer>
                <div className={styles.footerColWrap}>
                    <div className={styles.footerColWide}>
                        <Link to="/">
                            <Image
                                fixed={data.logoLight.childImageSharp.fixed}
                                style={{
                                    maxWidth: "100%"
                                }}
                                imgStyle={{
                                    width: "100%",
                                    height: "auto"
                                }}
                            />
                        </Link>
                        <div className={styles.otherMedia}>
                            <a target="_blank" rel="noreferrer" href={`${social.crunchbase}`}>
                                <Image
                                    fixed={data.crunchbase.childImageSharp.fixed}
                                    style={{
                                        maxWidth: "100%"
                                    }}
                                    imgStyle={{
                                        width: "100%",
                                        height: "auto"
                                    }}
                                />
                            </a>
                            <a target="_blank" rel="noreferrer" href={`${social.linkedin}`}>
                                <Image
                                    fixed={data.linkedin.childImageSharp.fixed}
                                    style={{
                                        maxWidth: "100%"
                                    }}
                                    imgStyle={{
                                        width: "100%",
                                        height: "auto"
                                    }}
                                />
                            </a>
                        </div>
                    </div>
                    <div className={styles.footerCol}>
                        <h2 className={styles.footerTitle}>Acerca de Open Green Road</h2>
                        <ul className={styles.links}>
                            <li>
                                <Link to="/prensa/">Prensa</Link>
                            </li>
                            <li>
                                <Link
                                    to="/contacto/"
                                    state={{
                                        modal: true
                                    }}
                                >
                                    Contacto
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className={styles.footerCol}>
                        <h2 className={styles.footerTitle}>Conoce nuestras Plataformas</h2>
                        <ul className={styles.links}>
                            <li>
                                <a target="_blank" rel="noreferrer" href="https://www.aprendolibre.com/">
                                    Aprendo Libre
                                </a>
                            </li>
                            <li>
                                <a target="_blank" rel="noreferrer" href="https://www.puntajenacional.cl/">
                                    Puntaje Nacional
                                </a>
                            </li>
                            <li>
                                <a target="_blank" rel="noreferrer" href="https://www.graduate.cl/">
                                    Gradúate
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </InnerContainer>
        </footer>
    )
}

export default Footer
