import React from "react"
import styled from "styled-components"
import Footer from "./footer"
import Header from "./header"
import InnerContainer from "./innerContainer"

const PageContainer = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`

const Layout = ({ location, title, children }) => {
    const rootPath = `${__PATH_PREFIX__}/`
    let header
    let main

    if (location.pathname === rootPath) {
        header = <div></div>
        main = <div>{children}</div>
    } else {
        header = <Header isHorizontal={true} title={title}></Header>
        main = <InnerContainer>{children}</InnerContainer>
    }

    return (
        <PageContainer style={{}}>
            <header>{header}</header>
            <main style={{ flexGrow: 1 }}>{main}</main>
            <Footer></Footer>
        </PageContainer>
    )
}

export default Layout
